import _ from 'lodash';
import { mapState } from 'vuex';
import graphQLUtilities from '../../services/graphQLUtilities/graphQLUtilities';

export default {
  data: () => ({
    applications: [],
    loading: true,
    inactiveStatus: false,
    search: '',
    options: {
      itemsPerPage: -1
    }
  }),
  async mounted() {
    await graphQLUtilities.listApplications(this.user.commonid);
    this.applications = this.loadActiveApplications();
    this.loading = false;
  },
  computed: {
    ...mapState('auth', ['user']),
    selected: function() {
      return this.$route.params.id;
    }
  },
  methods: {
    makingTheApplicationList(activeAppList) {
      let uniqueApps = _.uniqBy(activeAppList, 'Id');
      let orderedApps = _.orderBy(uniqueApps, [(app) => app.Name], ['asc']);
      let groupedApplications = _.groupBy(orderedApps, function(item) {
        return item.FriendlyName;
      });
      let orderedGroupedApps = _.orderBy(groupedApplications, [(group) => group[0].FriendlyName], ['asc']);
      return orderedGroupedApps;
    },
    loadActiveApplications() {
      let rawList = this.$store.state.applications.items;
      let activeApps = _.filter(rawList, function(a) {
        return a.AppHubStatus !== 'false';
      });
      let activeAppList = this.makingTheApplicationList(activeApps);
      return activeAppList;
    },
    loadAllApplications() {
      let allApps = this.$store.state.applications.items;
      let allAppList = this.makingTheApplicationList(allApps);
      return allAppList;
    },
    changeState(item) {
      if (item) {
        this.applications = this.loadAllApplications();
      } else {
        this.applications = this.loadActiveApplications();
      }
    }
  }
};
