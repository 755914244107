<template v-if="oidcIsAuthenticated">
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app clipped v-if="isAuthenticated">
      <sidebar />
    </v-navigation-drawer>

    <v-app-bar app clipped-left id="notification-service-global-header">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"> </v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link class="linkless-link" to="/"> Notification Service </router-link>
      </v-toolbar-title>
      <div class="spacer"></div>
      <router-link class="linkless-link" to="/admin" v-if="isAdmin"> Admin </router-link>
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app class="pa-1">
      <v-col cols="2" class="text-left pa-2">&copy;{{ new Date().getFullYear() }}</v-col>
      <v-col cols="8" class="text-center py-1" style="font-weight: bold; font-size: large">
        <span style="color:#c8102e !important"> Need Help? </span>
        <v-btn class="pa-3
         ma-1" href="https://shorty/nsdocs/" target="_blank" rounded small color="buttonGrey" dark> Documentation </v-btn>
        <v-btn class="pa-3 ma-1" href="mailto:RockCentralFoundationCustomDevTeam@rockcentraldetroit.com?subject=Trouble accessing Notification Service" rounded small color="buttonGrey" dark>
          Contact Team Foundation Engineering
        </v-btn>
        <v-btn class="pa-3 ma-1"
          href="https://teams.microsoft.com/l/channel/19%3ac7ae6c5cb2814aaf849b332aa17a51d9%40thread.tacv2/General?groupId=64a5d2d1-d556-4fbd-b6e2-b5a38401ada5&tenantId=e58c8e81-abd8-48a8-929d-eb67611b83bd"
          rounded
          small
          color="buttonGrey"
          dark
        >
          Notification Service Teams Room
        </v-btn>
      </v-col>
      <v-col cols="2" class="text-right pa-2">v{{ $store.getters.appVersion }}</v-col>
    </v-footer>

    <vue-snotify></vue-snotify>
    <notifier></notifier>
  </v-app>
</template>

<script>
import Vue from 'vue';
import Notifier from '@/components/notifier/notifier';
import { mapGetters } from 'vuex';
import sidebar from '@/components/sidebar/sidebar.vue';

export default Vue.extend({
  components: { Notifier, sidebar },
  props: {
    source: String
  },
  data: () => ({
    drawer: null
  }),
  created() {
    this.$vuetify.theme.dark = false;
    this.$vuetify.theme.light = true;
    document.title = process.env.VUE_APP_NAME;
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: ['auth/isAdmin'],
      isAuthenticated: ['auth/isAuthenticated'],
      oidcIsAuthenticated: ['auth/oidcIsAuthenticated']
    })
  }
});
</script>
<style src="vue-snotify/styles/material.css"></style>
<style scoped>
.linkless-link {
  text-decoration: none;
  color: inherit;
  margin-right: 2em;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.footer-buttons {
  height: 10px;
}
</style>
