import api from 'api-client';

const state = {
  applicationPermissions: {},
  user: {},
  users: [],
  applicationTeamUsers: []
};

const mutations = {
  setApplicationPermissions(statePassThru, data) {
    statePassThru.applicationPermissions = data.data.listApplicationPermissions;
  },
  setUser(statePassThru, data) {
    statePassThru.user = data.data.getTeamMemberByCommonId;
  },
  setUsers(statePassThru, data) {
    statePassThru.users = [];
    statePassThru.users = data.data.listTeamMembersByName;
  },
  setApplicationTeamUsers(statePassThru, data) {
    statePassThru.applicationTeamUsers = data.data.listTeamMembersBySubTeam;
  },
  setUserFromAuth(statePassThru, data) {
    statePassThru.user = data;
  }
};

const actions = {
  getApplicationPermissions({ commit }, data) {
    return api
      .teamMembers(data)
      .then(res => commit('setApplicationPermissions', res));
  },
  getUserByCommonId({ commit }, data) {
    return api.teamMembers(data).then(res => commit('setUser', res));
  },
  getApplicationTeam({ commit }, data) {
    return api.teamMembers(data).then(res => commit('setApplicationTeamUsers', res));
  },
  listTeamMembersByName({ commit }, data) {
    return api
      .teamMembers(data)
      .then(res => commit('setUsers', res));
  },
  getUserFromAuth({ commit }, data) {
    return commit('setUserFromAuth', data);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
