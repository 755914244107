import axios from 'axios';

const options = {
  baseURL: process.env.VUE_APP_HISTORY_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
};

const setBaseUrl = (stage) => {
  if (stage && process.env.VUE_APP_ENV === 'prod' && stage !== 'prod') {
    options.baseURL = process.env.VUE_APP_HISTORY_BETA_API_URL;
    return;
  }

  options.baseURL = process.env.VUE_APP_HISTORY_API_URL;
};

export default {
  listHistory(queryParams) {
    setBaseUrl(queryParams.stage);

    options.params = queryParams;

    return axios
      .get('/api/v1/emailtracking/', options)
      .then((response) => {
        return response;
      })
      .catch(() => { return null; });
  },
  listReadReceipts(queryParams) {
    setBaseUrl(queryParams.stage);

    options.params = queryParams;

    return axios
      .get('/api/v1/readreceipt/', options)
      .then((response) => {
        return response;
      })
      .catch(() => { return null; });
  }
};
