import 'core-js/stable'; // ie11 fix
import 'regenerator-runtime/runtime'; // ie11 fix
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import Vuelidate from 'vuelidate';
import axios from 'axios';
import Snotify, { SnotifyPosition } from 'vue-snotify';
import store from './store/store';
import VueLogger from 'vuejs-logger';
import { Auth0Plugin } from './auth';
import VueScrollactive from 'vue-scrollactive';

Vue.use(VueScrollactive);

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_SSO_DOMAIN,
  clientId: process.env.VUE_APP_SSO_CLIENT_ID,
  audience: process.env.VUE_APP_SSO_AUDIENCE,
  redirect_uri: process.env.VUE_APP_BASE_URL + process.env.VUE_APP_SSO_REDIRECT_URI,
  onRedirectCallback: (appState) => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  }
});

// Alert
const snotifyOptions = {
  toast: {
    position: SnotifyPosition.rightBottom,
    timeout: 1500,
    showProgressBar: true
  }
};

Vue.use(Vuelidate);
Vue.use(Snotify, snotifyOptions);
Vue.use(VueLogger, {
  isEnabled: true,
  logLevel: process.env.VUE_APP_LOG_LEVEL || 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
});
Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  created() {
    axios.interceptors.request.use(
      async config => {
        let tokenOptions = {
          appState: {
            targetUrl: router.currentRoute.fullPath
          }
        };
        const token = await this.$auth.getTokenSilently(tokenOptions);

        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  },
  render: h => h(App)
}).$mount('#app');
