export const hasRole = (authorizedRoles, userAdGroups) => {
  let doesHaveRole = false;

  for (const role of authorizedRoles) {
    for (const group of userAdGroups) {
      if (group === role) {
        doesHaveRole = true;
        break;
      }
    }
  }
  return doesHaveRole;
};
