import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      dark: {
        primary: '#FF1690',
        secondary: '#2DE2E6',
        info: '#791E94',
        warning: '#FF6C11',
        error: '#FD1D53',
        buttonGrey: '#545454'
      },
      light: {
        primary: '#C8102E',
        secondary: '#603AA1',
        info: '#791E94',
        warning: '#FF6C11',
        error: '#FD1D53',
        buttonGrey: '#545454'
      }
    }
  }
});
