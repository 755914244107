export const STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_SUCCESS = 'notifySuccess';
export const STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_ERROR = 'notifyError';
export const STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_INFO = 'notifyInfo';
export const STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_WARNING = 'notifyWarning';
export const STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_AUDIT = 'notifyAudit';

export const NOTIFY_SUCCESS = 'success';
export const NOTIFY_ERROR = 'error';
export const NOTIFY_INFO = 'info';
export const NOTIFY_WARNING = 'warning';
export const NOTIFY_AUDIT = 'audit';
