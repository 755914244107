export default {
  name: 'Notifier',
  computed: {
    notifications() {
      return this.$store.state.notifications.items;
    }
  },
  mounted() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'notifications/pushNotification') {
        const payload = mutation.payload;
        this.$snotify[payload.type](payload.message);
      }
    });
  },
  render(h) {
    return h();
  }
};
