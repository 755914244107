import {
  STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_SUCCESS,
  STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_ERROR,
  STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_INFO,
  STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_WARNING,
  STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_AUDIT,
  NOTIFY_SUCCESS,
  NOTIFY_ERROR,
  NOTIFY_INFO,
  NOTIFY_WARNING,
  NOTIFY_AUDIT
} from './store-notifications-types';

const state = {
  items: []
};

// actions
const actions = {
  [STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_SUCCESS]({ commit }, message) {
    commit('pushNotification', { type: NOTIFY_SUCCESS, message });
  },
  [STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_ERROR]({ commit }, message) {
    commit('pushNotification', { type: NOTIFY_ERROR, message });
  },
  [STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_INFO]({ commit }, message) {
    commit('pushNotification', { type: NOTIFY_INFO, message });
  },
  [STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_WARNING]({ commit }, message) {
    commit('pushNotification', { type: NOTIFY_WARNING, message });
  },
  [STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_AUDIT]({ commit }, message) {
    commit('pushNotification', { type: NOTIFY_AUDIT, message });
  }
};

// mutations
const mutations = {
  pushNotification(statePassThru, data) {
    statePassThru.items.push({ ...data });
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
