import Vue from 'vue';
import Vuex from 'vuex';
import notifications from './modules/notifications/store-notifications';
import auth from './modules/auth/store-auth';
import templates from './modules/templates/store-templates';
import applications from './modules/applications/store-applications';
import user from './modules/user/store-user';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    notifications,
    templates,
    applications,
    user
  },
  state: {
    packageVersion: process.env.PACKAGE_JSON_VERSION || '0.0.0'
  },
  getters: {
    appVersion: state => {
      return state.packageVersion;
    }
  }
});
