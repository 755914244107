import axios from 'axios';

const options = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
};

export default {
  templates(data) {
    return axios.post('', data, options).then(response => {
      return response;
    });
  },
  applications(data) {
    return axios.post('', data, options).then(response => {
      return response.data;
    });
  },
  teamMembers(data) {
    return axios.post('', data, options).then(response => {
      return response.data;
    });
  },
};
