import api from 'api-client';
import _ from 'lodash';

const state = {
  items: [],
  currentApplication: {},
  isApplicationLoading: false
};
const mutations = {
  setApplication(statePassThru, data) {
    if (!data) {
      statePassThru.currentApplication = {};
    } else {
      statePassThru.currentApplication = data;
    }
  },
  setApplications(statePassThru, data) {
    if (!data.data) {
      statePassThru.items = [];
    } else {
      statePassThru.items = data.data.listApplications;
    }
  },
  setAddPermission(statePassThru, data) {
    let app = statePassThru.items.find((application) => application.Id === data.appId);
    app.Roles.push({ Reason: `Permission modified by ${data.adminId}`, Role: data.roleId });
  },
  setRemovePermission(statePassThru, data) {
    let app = statePassThru.items.find((application) => application.Id === data.appId);
    app.Roles = _.uniqBy(app.Roles, 'Role');
    let index = app.Roles.findIndex((role) => {
      return role.Role === data.roleId;
    });
    app.Roles.splice(index, 1);
  },
  setIsApplicationLoading(statePassThru, data) {
    statePassThru.isApplicationLoading = data;
  }
};
const actions = {
  getApplication({ commit }, data) {
    commit('setIsApplicationLoading', true);
    if (data.query.Id) {
      commit('setApplication', data.query);
      commit('setIsApplicationLoading', false);
    } else {
      return api.applications(data).then(
        (res) => {
          commit('setApplication', res.data.getApplication);
          commit('setIsApplicationLoading', false);
        }
      );
    }
  },
  getApplications({ commit }, data) {
    return api.applications(data).then(res => commit('setApplications', res));
  },
  addRolePermission({ commit }, data) {
    return api.applications(data.query).then(() => {
      let commitData = { appId: data.query.appId, adminId: data.query.adminId, roleId: data.query.roleId };
      commit('setAddPermission', commitData);
    });
  },
  removeRolePermission({ commit }, data) {
    return api.applications(data.query).then(() => {
      let commitData = { appId: data.query.appId, roleId: data.query.roleId };
      commit('setRemovePermission', commitData);
    });
  }
};

const getters = {
  getCurrentApplication: state => id => {
    return state.items.find(application => application.Id === id);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
