export default {
  addDaysToDate(date, days) {
    if (typeof date.getMonth !== 'function') {
      return 'Date must be a Date object.';
    }
    let currentDay = date.getDate();
    let currentMonth = date.getMonth();
    let currentYear = date.getFullYear();
    let leapYear = currentYear % 4 === 0;
    let maxDays = this.checkMonthMaxDays(currentMonth, leapYear);

    if (currentDay + days > maxDays) {
      currentMonth = currentMonth + 1;
      currentDay = currentDay + days - maxDays;
    } else if (currentDay + days < 1) {
      currentMonth = currentMonth - 1 > -1 ? currentMonth - 1 : 11;
      maxDays = this.checkMonthMaxDays(currentMonth, leapYear);
      currentDay = maxDays + (currentDay + days);
      if (currentMonth === 11) {
        currentYear = currentYear - 1;
      }
    } else {
      currentDay = currentDay + days;
    }
    let result = new Date(currentYear, currentMonth, currentDay);
    return result;
  },
  checkMonthMaxDays(month, isLeapYear) {
    let thirtyDayMonths = [3, 5, 8, 10];
    if (!isLeapYear && month === 1) {
      return 28;
    } else if (isLeapYear && month === 1) {
      return 29;
    } else if (thirtyDayMonths.includes(month)) {
      return 30;
    } else {
      return 31;
    }
  },
  getMonthString(month) {
    if (month < 10) {
      return '0' + month;
    }
    return `${month}`;
  },
  getDayString(day) {
    if (day < 10) {
      return '0' + day;
    }
    return `${day}`;
  },
  createDateFromSimpleString(simpleDateString) {
    let year = simpleDateString.substr(0, 4);
    let month = simpleDateString.substr(5, 2);
    let day = simpleDateString.substr(8, 2);
    return new Date(year, month - 1, day);
  },
  createSimpleStringFromDate(date) {
    return `${date.getFullYear()}-${this.getMonthString(date.getMonth() + 1)}-${this.getDayString(date.getDate())}`;
  },
  formatDateTimeToString(dateTime, withAt = false) {
    let options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
    let date = new Date(dateTime);
    let withAtString = withAt ? ' at ' : ' ';

    return `${date.toLocaleString('en-US', options)}${withAtString}${date.toLocaleTimeString()}`;
  }
};
