import { hasRole } from './auth-utilities';

const ADMIN_ROLES = process.env.VUE_APP_ADMIN_ROLES
  ? process.env.VUE_APP_ADMIN_ROLES.split(',')
  : [];
const USER_ROLES = process.env.VUE_APP_USER_ROLES
  ? process.env.VUE_APP_USER_ROLES.split(',')
  : [];

const state = {
  isAdmin: false,
  isUser: false,
  adminRoles: ADMIN_ROLES,
  userRoles: USER_ROLES,
  isAuthenticated: false,
  user: {},
  token: ''
};

const mutations = {
  setIsAdmin(statePassThru, data) {
    statePassThru.isAdmin = data;
  },
  setIsAuthenticated(statePassThru, data) {
    statePassThru.isAuthenticated = data;
  },
  setUserFromAuth(statePassThru, data) {
    statePassThru.user = data;
  },
  setToken(statePassThru, data) {
    statePassThru.token = data;
  }
};

const getters = {
  isAdmin: (statePassThru) => {
    return statePassThru.isAdmin;
  },
  isAuthenticated: (statePassThru) => {
    return statePassThru.isAuthenticated;
  },
  isUser: (statePassThru) => {
    return statePassThru.isUser;
  },
  user: (statePassThru) => {
    return statePassThru.user;
  }
};

const actions = {
  async checkAdminAccess(context, user) {
    const isAdmin = user ? hasRole(context.state.adminRoles, user['https://ql.custom.openid.com/groups']) : false;
    context.commit('setIsAdmin', isAdmin);
    return isAdmin;
  },
  getIsAuthenticated({ commit }, data) {
    return commit('setIsAuthenticated', data);
  },
  getUserFromAuth({ commit }, data) {
    let user = {
      commonid: data['https://ql.custom.openid.com/common_id'],
      ADGroups: data['https://ql.custom.openid.com/groups'],
      subteam: data['https://ql.custom.openid.com/subteam'],
      LastName: data.family_name,
      FirstName: data.given_name,
      authData: data
    };
    return commit('setUserFromAuth', user);
  },
  getTokenFromAuth({ commit }, data) {
    return commit('setToken', data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
