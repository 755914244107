import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/store';
import { authGuard } from './auth/auth-guard';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/application/:id',
      name: 'application',
      props: true,
      component: () => import('./views/Application/Application.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import('./views/admin/admin.vue'),
      beforeEnter: (to, from, next) => {
        if (store.state.isAdmin === false) {
          next(false);
        } else {
          next();
        }
      }
    },
    {
      path: '/application/:id/template/:templateId/version/:versionId',
      name: 'template',
      component: () => import('./views/Template/Template.vue'),
      props: true,
      beforeEnter: authGuard
    },
    {
      path: '/application/:id/template',
      name: 'createTemplate',
      component: () => import('./views/Template/Create/CreateTemplate.vue'),
      props: true,
      beforeEnter: authGuard
    },
    {
      path: '/auth/signed-in',
      name: 'signed-in',
      component: () => import('./views/auth/signed-in.vue'),
      meta: {
        isAuthCallback: true
      },
      beforeEnter: authGuard
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: () => import('./views/auth/unauthorized.vue'),
      meta: {
        isPublic: true,
        isLoginCallbackError: true
      }
    },
    {
      path: '/forbidden',
      name: 'forbidden',
      component: () => import('./views/auth/forbidden.vue')
    },
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home/Home.vue'),
      beforeEnter: authGuard
    },
    {
      path: '*',
      redirect: 'home',
      beforeEnter: authGuard
    }
  ]
});

export default router;
